<mat-sidenav-container [ngStyle] = "{ 'position' : sidenav.opened ? 'fixed' : 'relative'}">
  <mat-sidenav #sidenav role="navigation">
    <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <main>
      <app-menu (sidenavToggle)="sidenav.toggle()"></app-menu>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
      <a *ngIf="isShow" (click)="gotoTop()">
        <span class="material-icons">
          expand_less
        </span>
      </a>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>