import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-privacy-policy-page',
    templateUrl: './privacy.policy.component.html',
    styleUrls: ['./privacy.policy.component.scss'],
    providers: []
})

export class PrivacyPolicyComponent implements OnInit {

    constructor() {
    }
    
    ngOnInit() {
        window.scroll({
            top: 0
          });
    }

}
