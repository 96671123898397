import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-sidenav-list',
    templateUrl: './sidenav.list.component.html',
    styleUrls: ['./sidenav.list.component.scss']
})
export class SidenavListComponent implements OnInit {


    languageSwitch: boolean;
    hidden: boolean = true;
    show: boolean = false;

    @Output() sidenavClose = new EventEmitter();

    constructor(public router: Router, public translate: TranslateService) {
        translate.addLangs(['en', 'fr']);
        if (navigator.language == 'fr')
        {
            translate.setDefaultLang('fr');
            this.languageSwitch = true;
        } else {
            translate.setDefaultLang('en');
            this.languageSwitch = false;
        }
    }

    ngOnInit() {
    }

    useLanguage(language: string) {
        this.translate.use(language);
        if( language == 'fr' ){
            this.languageSwitch = true;
        } if ( language == 'en' ) {
            this.languageSwitch = false;
        }
        this.onSidenavClose();
    }

    public onSidenavClose = () => {
        this.hidden = true;
        this.show = false;
        this.sidenavClose.emit();
    }

    public changeClass() {
        if (this.hidden) {
            this.hidden = false;
            this.show = true;
        } else {
            this.hidden = true;
            this.show = false;
        }
    }
}
