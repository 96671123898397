<section class="first-section">
    <div class="first-section-container">
        <div class="div-title">
            <div class="title">
                <h1>Bib+</h1>
            </div>
        </div>
        <div class="div-subTitle">
            <div class="subTitle">
                <h2>{{ 'home.subTitle' | translate }}</h2>
            </div>
        </div>
        <div class="div-download-img">
            <a href="http://itunes.apple.com/app/id1455321304">
                <img class="download-img" src="../../../assets/img/appStore_Download_Img.png" alt="appStore-download">
            </a>
        </div>
    </div>
</section>
<section class="section-iphone_carnet">
    <div class="section-iphone_carnet-container">
        <div class="section-iphone_carnet-container-image">
            <img class="section-iphone_carnet-container-img" src="../../../assets/img/iphone_Exemple_homePage.png" alt="iphone_carnet">
        </div>
    </div>
</section>

<section class="counter-section" *ngIf="firstAnimationShow">
    <div class="counter-container">
        <div class="counter-one-container">
            <div class="counter-one">
                <span class="prefix"></span>
                <span [countUp]="1200" [reanimateOnClick]="false">0</span>
                <span class="suffix"></span>
            </div>
            <div class="counter-one-title-container">
                <div class="counter-one-title">{{ 'home.counter.babyBottles' | translate }}</div>
            </div>
        </div>
        <div class="counter-two-container">
            <div class="counter-two">
                <span class="prefix"></span>
                <span [countUp]="400" [reanimateOnClick]="false">0</span>
                <span class="suffix"></span>
            </div>
            <div class="counter-two-title-container">
                <div class="counter-two-title">{{ 'home.counter.babies' | translate }}</div>
            </div>
        </div>
        <div class="counter-three-container">
            <div class="counter-three">
                <span class="prefix"></span>
                <span [countUp]="873" [reanimateOnClick]="false">0</span>
                <span class="suffix"></span>
            </div>
            <div class="counter-three-title-container">
                <div class="counter-three-title">{{ 'home.counter.installations' | translate }}</div>
            </div>
        </div>
    </div>
</section>
<section class="health-book">
    <div class="svg-container">
        <img src="../../../assets/img/health_book_svg.svg" alt="">
    </div>
    <div class="health-book-container">
        <div class="health-book-title">
            <h3 [innerHTML]="'home.healthBook.title' | translate"></h3>
        </div>
        <div class="health-book-divider">
            <span class="divider"></span>
        </div>
        <div class="health-book-paragraphe">
            <p>{{ 'home.healthBook.paragrapheOne' | translate }}</p>
            <p>{{ 'home.healthBook.paragrapheTwo' | translate }}</p>
            <p>{{ 'home.healthBook.paragrapheThree' | translate }}</p>
        </div>
        <div class="health-book-app-download">
            <a href="https://itunes.apple.com/us/app/websocket-client/id1399498094?mt=12">
                <span>
                    <mat-icon class="app-download-icon">get_app</mat-icon>
                    <span>{{ 'home.button.download' | translate }}
                    </span>
                </span>
            </a>
        </div>
    </div>
</section>
<section class="characteristics">
    <div class="characteristics-container">
        <div class="add-container" *ngIf="secondAnimationShow">
            <div class="characteristics-title">
                <h2>{{ 'home.characteristics.title.one' | translate }}</h2>
            </div>
            <div class="characteristics-description">
                <p>{{ 'home.characteristics.description.one' | translate }}</p>
            </div>
        </div>
        <div class="statistical-container" *ngIf="thirdAnimationShow">
            <div class="characteristics-title">
                <h2>{{ 'home.characteristics.title.two' | translate }}</h2>
            </div>
            <div class="characteristics-description">
                <p>{{ 'home.characteristics.description.two' | translate }}</p>
            </div>
        </div>
        <div class="share-container" *ngIf="fourthAnimationShow">
            <div class="characteristics-title">
                <h2>{{ 'home.characteristics.title.three' | translate }}</h2>
            </div>
            <div class="characteristics-description">
                <p>{{ 'home.characteristics.description.three' | translate }}</p>
            </div>
        </div>
        <div class="notifications-container" *ngIf="fifhAnimationShow">
            <div class="characteristics-title">
                <h2>{{ 'home.characteristics.title.four' | translate }}</h2>
            </div>
            <div class="characteristics-description">
                <p [innerHTML]="'home.characteristics.description.four' | translate"></p>
            </div>
        </div>
    </div>
</section>

<section class="section-iphone_carnet iphone_carnet-details" *ngIf="sixthAnimationShow">
    <div class="section-iphone_carnet-container">
        <div class="section-iphone_carnet-container-image">
            <img class="section-iphone_carnet-container-img" src="../../../assets/img/iphone_Exemple_dashBoard.png" alt="iphone_carnet-details">
        </div>
    </div>
</section>

<section class="description-section">
    <div class="description-container">
        <div class="description-title">
            <h3>{{ 'home.description.title' | translate }}</h3>
        </div>
        <div class="description-paragraphes">
            <p>{{ 'home.description.paragrapheOne' | translate }}</p>
            <p [innerHTML]="'home.description.paragrapheTwo' | translate"></p>
            <p>{{ 'home.description.paragrapheThree' | translate }}</p>
        </div>
    </div>
    <div class="description-app-download">
        <a href="https://itunes.apple.com/us/app/websocket-client/id1399498094?mt=12">
            <span>
                <mat-icon class="description-app-download-icon">get_app</mat-icon>
                <span>{{ 'home.button.download' | translate }}
                </span>
            </span>
        </a>
    </div>
</section>
<section class="final-section">
    <div class="final-section-container">
        <div class="bibplus-img">
            <img src="../../../assets/img/logo_application.png" alt="logo_bib+">
        </div>
        <div class="madeby-section">
            <div class="madeby-title">
                <h4>Made by</h4>
            </div>
            <div class="madeby-description" *ngIf="seventhAnimationShow">
                <a href="https://awayup.io" data-elementor-open-lightbox="">
                    <img class="madeby-img" src="../../../assets/img/AwayUp-logo.png" alt="AwayUp-logo">
                </a>
            </div>
        </div>
    </div>
</section>