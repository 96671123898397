<section class="terms-of-service-section">
    <div class="terms-of-service-section-container">
        <div class="header-title-container">
            <div class="header-title">
                <h1>Bib+</h1>
            </div>
        </div>
        <div class="div-download-img">
            <a href="http://itunes.apple.com/app/id1455321304">
                <img class="download-img" src="../../../assets/img/appStore_Download_Img.png" alt="appStore-download">
            </a>
        </div>
        <div>
            <div class="terms-of-service-title">
                <h1>{{ 'termsOfService.title.one' | translate }}</h1>
            </div>
            <div class="container">
                <p>{{ 'termsOfService.paragraphes.one' | translate }}</p>
            </div>
        </div>
        <div>
            <div class="title">
                <h1>{{ 'termsOfService.title.two' | translate }}</h1>
            </div>
            <div class="container">
                <p>{{ 'termsOfService.paragraphes.two' | translate }}</p>
            </div>
        </div>
        <div>
            <div class="title">
                <h1>{{ 'termsOfService.title.three' | translate }}</h1>
            </div>
            <div class="container">
                <p>{{ 'termsOfService.paragraphes.three' | translate }}</p>
            </div>
        </div>
        <div>
            <div class="title">
                <h1>{{ 'termsOfService.title.four' | translate }}</h1>
            </div>
            <div class="container">
                <p>{{ 'termsOfService.paragraphes.four' | translate }}</p>
                <p>{{ 'termsOfService.paragraphes.five' | translate }}</p>
                <p>{{ 'termsOfService.paragraphes.six' | translate }}</p>
            </div>
        </div>
        <div>
            <div class="title">
                <h1>{{ 'termsOfService.title.five' | translate }}</h1>
            </div>
            <div class="container">
                <p>{{ 'termsOfService.paragraphes.seven' | translate }}</p>
            </div>
        </div>
        <div>
            <div class="title">
                <h1>{{ 'termsOfService.title.six' | translate }}</h1>
            </div>
            <div class="container">
                <p>{{ 'termsOfService.paragraphes.eight' | translate }}</p>
                <P>{{ 'termsOfService.paragraphes.nine' | translate }}</P>
            </div>
        </div>
        <div>
            <div class="title">
                <h1>{{ 'termsOfService.title.seven' | translate }}</h1>
            </div>
            <div class="container">
                <p>{{ 'termsOfService.paragraphes.ten' | translate }}</p>
            </div>
        </div>
        <div>
            <div class="title">
                <h1>{{ 'termsOfService.title.eight' | translate }}</h1>
            </div>
            <div class="container">
                <p>{{ 'termsOfService.paragraphes.eleven' | translate }}</p>
            </div>
        </div>
        <div>
            <div class="title">
                <h1>{{ 'termsOfService.title.nine' | translate }}</h1>
            </div>
            <div class="container">
                <p>{{ 'termsOfService.paragraphes.twelve' | translate }}</p>
            </div>
        </div>
    </div>
</section>
<div class="madeby-section">
    <div class="madeby-title">
        <h2>Made by</h2>
    </div>
    <div class="madeby-description">
        <a href="https://awayup.io">
            <img class="madeby-img" src="../../../assets/img/AwayUp-logo-white.png" alt="AwayUp-logo">
        </a>
    </div>

</div>

<section class="triangle-section">
    <div class="triangle-container">
        <img src="../../../assets/img/triangle_svg.svg" alt="">
    </div>
</section>