import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-terms-of-services-page',
    templateUrl: './terms.of.service.component.html',
    styleUrls: ['./terms.of.service.component.scss'],
    providers: []
})
export class TermsOfServicesComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        window.scroll({
            top: 0
          });
    }
}
