import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: []
})

export class HomeComponent implements OnInit {

  scrollPosition: number;

  firstAnimationShow: boolean;
  secondAnimationShow: boolean;
  thirdAnimationShow: boolean;
  fourthAnimationShow: boolean;
  fifhAnimationShow: boolean;
  sixthAnimationShow: boolean;
  seventhAnimationShow: boolean;

  firstAnimation: number;
  secondAnimation: number;
  thirdAnimation: number;
  fourthAnimation: number;
  fifhAnimation: number;
  sixthAnimation: number;
  seventhAnimation: number;

  constructor() {
  }
  ngOnInit() {
    window.scroll({
      top: 0
    });

    // scrollPosition for smartphone (iPhone x) //

    if (window.matchMedia("(min-width:350px)").matches) {
      this.firstAnimation = 1;
      this.secondAnimation = 1350;
      this.thirdAnimation = 1500;
      this.fourthAnimation = 1750;
      this.fifhAnimation = 1900;
      this.sixthAnimation = 2150;
      this.seventhAnimation = 3900;
      this.checkScroll();
    }

    // scrollPosition for smallTablet (iPad) //

    if (window.matchMedia("(min-width:767px)").matches) {
      this.firstAnimation = -1;
      this.secondAnimation = 800;
      this.thirdAnimation = 800;
      this.fourthAnimation = 800;
      this.fifhAnimation = 800;
      this.sixthAnimation = 1100;
      this.seventhAnimation = 2600;
      this.checkScroll();
      
    }

        // scrollPosition for largeTablet (iPad Pro) //

    if (window.matchMedia("(min-width:992px)").matches) {
      this.firstAnimation = -1;
      this.secondAnimation = 715;
      this.thirdAnimation = 715;
      this.fourthAnimation = 715;
      this.fifhAnimation = 715;
      this.sixthAnimation = 1100;
      this.seventhAnimation = 2600;
      this.checkScroll();
    }

        // scrollPosition for smallDesktop //

    if (window.matchMedia("(min-width:1200px)").matches) {
      this.firstAnimation = 186;
      this.secondAnimation = 1200;
      this.thirdAnimation = 1200;
      this.fourthAnimation = 1200;
      this.fifhAnimation = 1200;
      this.sixthAnimation = 1600;
      this.seventhAnimation = 3250;
      this.checkScroll();
    }

            // scrollPosition for largeDesktop //

    if (window.matchMedia("(min-width:1920px)").matches) {
      this.firstAnimation = 540;
      this.secondAnimation = 1550;
      this.thirdAnimation = 1550;
      this.fourthAnimation = 1550;
      this.fifhAnimation = 1550;
      this.sixthAnimation = 1960;
      this.seventhAnimation = 4000;
      this.checkScroll();
    }
  }

  @HostListener('window:scroll')

  checkScroll() {
    
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.firstAnimation) {
      this.firstAnimationShow = true;
    } else {
      this.firstAnimationShow = false;
    }

    if (scrollPosition >= this.secondAnimation) {
      this.secondAnimationShow = true;
    } else {
      this.secondAnimationShow = false;
    }

    if (scrollPosition >= this.thirdAnimation) {
      this.thirdAnimationShow = true;
    } else {
      this.thirdAnimationShow = false;
    }

    if (scrollPosition >= this.fourthAnimation) {
      this.fourthAnimationShow = true;
    } else {
      this.fourthAnimationShow = false;
    }

    if (scrollPosition >= this.fifhAnimation) {
      this.fifhAnimationShow = true;
    } else {
      this.fifhAnimationShow = false;
    }

    if (scrollPosition >= this.sixthAnimation) {
      this.sixthAnimationShow = true;
    } else {
      this.sixthAnimationShow = false;
    }

    if (scrollPosition >= this.seventhAnimation) {
      this.seventhAnimationShow = true;
    } else {
      this.seventhAnimationShow = false;
    }

  }

}
