<mat-toolbar class="mat-elevation-z6 navbar">
    <div class="logo">
        <a routerLink="/home" routerLinkActive="active">
            <img class="custom-logo" src="../../../assets/img/logo_application_white.png" alt="Bibplus">
        </a>
    </div>
    <div fxFlex fxLayout fxLayoutAlign="end center" fxHide.lt-md>
        <ul fxLayout fxLayoutGap="15px" class="navigation-items">
            <li>
                <a routerLink="/terms-of-services" routerLinkActive="active">
                    <span>{{ 'menu.link.first' | translate }}</span>
                </a>
            </li>
            <li>
                <a routerLink="/privacy-policy" routerLinkActive="active">
                    <span>{{ 'menu.link.second' | translate }}</span>
                </a>
            </li>
            <li>
                <a href="https://awayup.io/" routerLinkActive="active">
                    <span>AwayUp Agency</span>
                </a>
            </li>

            <!-- French -->

            <li *ngIf="languageSwitch">

                <!-- Not iPad Pro -->

                <a *ngIf="!minWidth" class="drop-down" routerLinkActive="active">
                    <span><img class="flag" src="../../../assets/img/flag_fr_FR.png" alt="Francais"></span>
                    <span (click)="useLanguage('fr')">{{ 'menu.link.third' | translate }}</span>
                    <mat-icon class="arrow-icon">keyboard_arrow_down</mat-icon>
                    <ul>
                        <li>
                            <a class="sub-menu" routerLinkActive="active">
                                <span><img class="flag" src="../../../assets/img/flag_en_US.png" alt="English"></span>
                                <span (click)="useLanguage('en')">English</span>
                            </a>
                        </li>
                    </ul>
                </a>

                <!-- iPad Pro -->

                <a *ngIf="minWidth" class="drop-down" routerLinkActive="active">
                    <span><img class="flag" src="../../../assets/img/flag_fr_FR.png" alt="Francais"></span>
                    <span (click)="useLanguage('fr')">{{ 'menu.link.third' | translate }}</span>
                    <span (click)="changeClass()" class="material-icons plus">keyboard_arrow_down</span>
                    <ul>
                        <li>
                            <a [ngClass]="{'hidden':hidden, 'show':show}" routerLinkActive="active">
                                <span><img class="flag" src="../../../assets/img/flag_en_US.png" alt="English"></span>
                                <span (click)="useLanguage('en');changeClass();">English</span>
                            </a>
                        </li>
                    </ul>
                </a>
            </li>

            <!-- English -->

            <li *ngIf="!languageSwitch">

                <!-- Not iPad Pro -->

                <a *ngIf="!minWidth" class="drop-down" routerLinkActive="active">
                    <span><img class="flag" src="../../../assets/img/flag_en_US.png" alt="English"></span>
                    <span (click)="useLanguage('en')">English</span>
                    <mat-icon class="arrow-icon">keyboard_arrow_down</mat-icon>
                    <ul>
                        <li>
                            <a class="sub-menu" routerLinkActive="active">
                                <span><img class="flag" src="../../../assets/img/flag_fr_FR.png" alt="Francais"></span>
                                <span (click)="useLanguage('fr')">{{ 'menu.link.third' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </a>

                <!-- iPad Pro -->

                <a *ngIf="minWidth" class="drop-down" routerLinkActive="active">
                    <span><img class="flag" src="../../../assets/img/flag_en_US.png" alt="English"></span>
                    <span (click)="useLanguage('en')">English</span>
                    <span (click)="changeClass()" class="material-icons plus">keyboard_arrow_down</span>
                    <ul>
                        <li>
                            <a [ngClass]="{'hidden':hidden, 'show':show}" routerLinkActive="active">
                                <span><img class="flag" src="../../../assets/img/flag_fr_FR.png" alt="Francais"></span>
                                <span (click)="useLanguage('fr');changeClass();">{{ 'menu.link.third' | translate }}</span>
                            </a>
                        </li>
                    </ul>
                </a>
            </li>
        </ul>
    </div>
    <div class="menu-button" fxHide.gt-sm>
        <button [disableRipple]="true" mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
</mat-toolbar>