import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import { PrivacyPolicyComponent } from './page/privacy-policy/privacy.policy.component';
import { TermsOfServicesComponent } from './page/terms-of-service/terms.of.service.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-of-services', component: TermsOfServicesComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
