<mat-nav-list>
    <ul class="navigation-items">
        <li class="border">
            <a (click)="onSidenavClose()" routerLink="/terms-of-services" routerLinkActive="active">
                <span>{{ 'menu.link.first' | translate }}</span>
            </a>
        </li>
        <li class="border">
            <a (click)="onSidenavClose()" routerLink="/privacy-policy" routerLinkActive="active">
                <span>{{ 'menu.link.second' | translate }}</span>
            </a>
        </li>
        <li class="border">
            <a (click)="onSidenavClose()" href="https://awayup.io/" routerLinkActive="active">
                <span>AwayUp Agency</span>
            </a>
        </li>
        <li *ngIf="languageSwitch" class="border">
            <a routerLinkActive="active">
                <span><img class="flag" src="../../../assets/img/flag_fr_FR.png" alt="Francais"></span>
                <span (click)="useLanguage('fr')">{{ 'menu.link.third' | translate }}</span>
                <span (click)="changeClass()" class="material-icons plus">keyboard_arrow_down</span>
                <ul>
                    <li>
                        <a [ngClass]="{'hidden':hidden, 'show':show}" routerLinkActive="active">
                            <span><img class="flag" src="../../../assets/img/flag_en_US.png" alt="English"></span>
                            <span (click)="useLanguage('en')">English</span>
                        </a>
                    </li>
                </ul>
            </a>
        </li>
        <li *ngIf="!languageSwitch" class="border">
            <a routerLinkActive="active">
                <span><img class="flag" src="../../../assets/img/flag_en_US.png" alt="English"></span>
                <span (click)="useLanguage('en')">English</span>
                <span (click)="changeClass()" class="material-icons plus">add</span>
                <ul>
                    <li>
                        <a [ngClass]="{'hidden':hidden, 'show':show}" routerLinkActive="active">
                            <span><img class="flag" src="../../../assets/img/flag_fr_FR.png" alt="Francais"></span>
                            <span (click)="useLanguage('fr')">{{ 'menu.link.third' | translate }}</span>
                        </a>
                    </li>
                </ul>
            </a>
        </li>
    </ul>
    <img class="img-logo" src="../../../assets/img/logo_application.png" alt="">
</mat-nav-list>