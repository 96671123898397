import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

    languageSwitch: boolean;
    minWidth: boolean;
    hidden: boolean = true;
    show: boolean = false;

    

    @Output() public sidenavToggle = new EventEmitter();

    constructor(public router: Router, public translate: TranslateService) {
        translate.addLangs(['en', 'fr']);
        if (navigator.language == 'fr')
        {
            translate.setDefaultLang('fr');
            this.languageSwitch = true;
        } else {
            translate.setDefaultLang('en');
            this.languageSwitch = false;
        }
    }

    ngOnInit() {
        if (window.matchMedia("(max-width:1024px)").matches) {
            this.minWidth = true;
          } else {
            this.minWidth = false;
          }
    }

    useLanguage(language: string) {
        this.translate.use(language);
        if( language == 'fr' ){
            this.languageSwitch = true;
        } if ( language == 'en' ) {
            this.languageSwitch = false;
        }
    }

    public onToggleSidenav = () => {
        this.sidenavToggle.emit();
    }

    public changeClass() {
        if (this.hidden) {
            this.hidden = false;
            this.show = true;
        } else {
            this.hidden = true;
            this.show = false;
        }
    }
}
